import { retrieve_item } from "@src/helpers/cryptos";
import { useAPIRequest } from "@src/helpers/hooks";
import { SEARCH_IMAGE } from "@src/helpers/image_constants";
import { get_search_result_list_query } from "@src/services/graphql/graphql_search_results";
import { Button, Form, Image, Input } from "antd";
import { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import { IoSearch } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const HeaderSearchMobileView = (props) => {
  const { search_text } = props;
  const language = retrieve_item("language");
  const navigate = useNavigate(); // Corrected hook
  const inputRef = useRef(null);
  const [page_limit, set_page_limit] = useState(8);
  const [result_type, set_result_type] = useState("article");
  const [search_value, set_search_value] = useState("");

  const [form] = Form.useForm();
  const [initial_values, set_initial_values] = useState({});

  let get_search_results = useAPIRequest(
    "get_search_result_list",
    get_search_result_list_query
  );

  const fetch_data = (search_value) => {
    get_search_results(
      {
        search_string: search_value,
        page_limit: page_limit,
        language_id: language?.id,
        type: result_type,
      },
      true
    );
  };
  const on_finish = (value) => {
    set_search_value(value?.search_text);
  };
  const handle_submit = (search_value) => {
    set_search_value(search_value);
    const queryParams = new URLSearchParams();
    queryParams.set("type", search_value.trim());
    navigate({
      pathname: "/search-results",
      search: `?${queryParams.toString()}`,
    });
  };

  useEffect(() => {
    if (search_text) {
      const search_value = search_text || "";
      form.setFieldsValue({
        search_text: search_value,
      });
      set_initial_values({
        search_text: search_value,
      });
    }
  }, [search_text]);

  useEffect(() => {
    if (search_value) {
      const queryParams = new URLSearchParams();
      queryParams.set("type", search_value.trim());
      navigate({
        pathname: "/search-results",
        search: `?${queryParams.toString()}`,
      });
    }
  }, [search_value]);
  useEffect(() => {
    inputRef.current?.focus(); // Focus the input field on mount
  }, []);
  return (
    <div className="search_container">
      <div className="search_container_result_text_container">
        <h3 className="search_container_result_text"></h3>
        <div className="search_container_form_container">
          <Form
            name="search"
            form={form}
            onFinish={on_finish}
            initialValues={initial_values}
            className="search_form"
          >
            <Form.Item name="search_text" className="search_input_item">
              <Input className="search_input" ref={inputRef} />
            </Form.Item>
          </Form>
          <Button
            onClick={() => {
              let value = form.getFieldValue("search_text");
              handle_submit(value);
            }}
            type="primary"
            htmlType="submit"
            className="search_button"
          >
            <span className="search_text">{t("search")}</span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default HeaderSearchMobileView;
