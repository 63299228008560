// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.editor_profile_image {
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  min-width: 140px !important;
}
.contributions_card_wrapper {
  background-color: #ffffff;
  height: 200px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  cursor: pointer;
}
.writer_profile_wrapper {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
}

.author_contribution_list_container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 30px;
  padding: 2%;
}
.social_media_urls {
  width: 40px;
}
`, "",{"version":3,"sources":["webpack://./src/styles/layout/editor_profile.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,kBAAkB;EAClB,iBAAiB;EACjB,2BAA2B;AAC7B;AACA;EACE,yBAAyB;EACzB,aAAa;EACb,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,SAAS;EACT,eAAe;AACjB;AACA;EACE,aAAa;EACb,6BAA6B;EAC7B,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,iBAAiB;EACjB,WAAW;AACb;AACA;EACE,WAAW;AACb","sourcesContent":[".editor_profile_image {\n  height: 100%;\n  border-radius: 50%;\n  object-fit: cover;\n  min-width: 140px !important;\n}\n.contributions_card_wrapper {\n  background-color: #ffffff;\n  height: 200px;\n  border-radius: 10px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  gap: 15px;\n  cursor: pointer;\n}\n.writer_profile_wrapper {\n  display: flex;\n  justify-content: space-around;\n  flex-direction: column;\n  align-items: center;\n}\n\n.author_contribution_list_container {\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n  padding-top: 30px;\n  padding: 2%;\n}\n.social_media_urls {\n  width: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
