// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .contact_us_section_wrapper {
    width: 100%;
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-between !important;
    padding: 0% !important;
  }
  .contact_us_form_container {
    width: 100% !important;
    gap: 15px;
    padding: 5%;
  }
  .contact_us_donation_wrapper {
    width: 100%;
  }
  .contact_info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-bottom: 20px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/screen_768_1023/screen_768_1023_contact_us.css"],"names":[],"mappings":"AAAA;EACE;IACE,WAAW;IACX,wBAAwB;IACxB,iCAAiC;IACjC,yCAAyC;IACzC,sBAAsB;EACxB;EACA;IACE,sBAAsB;IACtB,SAAS;IACT,WAAW;EACb;EACA;IACE,WAAW;EACb;EACA;IACE,aAAa;IACb,sBAAsB;IACtB,8BAA8B;IAC9B,mBAAmB;IACnB,WAAW;IACX,oBAAoB;EACtB;AACF","sourcesContent":["@media only screen and (min-width: 768px) and (max-width: 1023px) {\n  .contact_us_section_wrapper {\n    width: 100%;\n    display: flex !important;\n    flex-direction: column !important;\n    justify-content: space-between !important;\n    padding: 0% !important;\n  }\n  .contact_us_form_container {\n    width: 100% !important;\n    gap: 15px;\n    padding: 5%;\n  }\n  .contact_us_donation_wrapper {\n    width: 100%;\n  }\n  .contact_info {\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    align-items: center;\n    width: 100%;\n    padding-bottom: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
