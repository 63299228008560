import { retrieve_item } from "@src/helpers/cryptos";
import { ensure_https } from "@src/helpers/functions";
import { useAPIRequest } from "@src/helpers/hooks";
import { USER_PROFILE } from "@src/helpers/image_constants";
import { ROUTES } from "@src/route/my_routes";
import { get_user_query } from "@src/services/graphql/graphql_user_profile";
import { useDynamicSelector } from "@src/services/redux";
import { Dropdown } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

const HeaderMenus = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const session_id = retrieve_item("session_id");
  let get_user = useAPIRequest("get_user", get_user_query);
  const user_id = retrieve_item("user_id");

  const { user_profile } = useDynamicSelector("get_user");

  const [menuItems, setMenuItems] = useState([]);

  let menu_items = [
    { href: ROUTES.FACT_CHECK, label: "Fact Check" },
    { href: ROUTES.ARTICLES, label: "Articles" },
    { href: ROUTES.VIDEOS, label: "Videos" },
    { href: ROUTES.ABOUT_US, label: "About Us" },
    { href: ROUTES.LOGIN, label: "Login" },
  ];

  const items = [
    {
      key: ROUTES.USER_PROFILE,
      label: t("profile"),
    },
    {
      key: ROUTES.USER_ARTICLE_FORM,
      label: t("create_article"),
    },
    {
      key: ROUTES.USER_FACT_CHECK_FORM,
      label: t("create_fact_check"),
    },
    {
      key: "logout",
      label: t("logout"),
    },
  ];

  const get_user_details = (id) => {
    get_user({
      id,
    });
  };
  const handle_redirect = (path) => {
    navigate(path);
  };

  useEffect(() => {
    if (session_id) {
      setMenuItems(menu_items.filter((menu) => menu.href !== ROUTES.LOGIN));
      get_user_details(user_id);
    } else {
      setMenuItems(menu_items);
    }
  }, [session_id]);

  const handle_change_menu = (value) => {
    if (value?.key?.includes("logout")) {
      navigate(ROUTES.HOME);
      window.location.reload();
      window.localStorage.clear();
    } else {
      navigate(value?.key);
    }
  };

  const image_url = ensure_https(user_profile?.profile_pic);

  return (
    <div className="user_layout_menu_container ">
      {menuItems.map((item) => (
        <span
          key={item.href}
          href={item.href}
          className="user_layout_menus"
          onClick={() => handle_redirect(item.href)}
        >
          {item.label}
        </span>
      ))}
      {user_id && (
        <Dropdown
          menu={{
            items,
            onClick: handle_change_menu,
            selectable: true,
            defaultValue: [pathname],
          }}
          placement="bottomRight"
          // onOpenChange={handle_change_menu}
        >
          <div className="user_profile_container">
            <img
              alt={"YouTurn"}
              src={image_url || USER_PROFILE}
              className="user_profile"
            />
          </div>
        </Dropdown>
      )}
    </div>
  );
};

export default HeaderMenus;
