// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (min-width: 1440px) and (max-width: 2559px) {
  .user_contribution_card_skeleton_width {
    width: 550px;
    margin: 10px;
    height: 100px;
  }
  .top_contributors_section_mobile_view {
    display: none;
  }
  .user_contributors_list {
    grid-template-columns: 25% 25% 25% 25%;
  }
  .user_contribution_title_ta {
    font-size: 28px !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/screen_1440_1999/screen_1440_2559_user_contribution.css"],"names":[],"mappings":"AAAA;EACE;IACE,YAAY;IACZ,YAAY;IACZ,aAAa;EACf;EACA;IACE,aAAa;EACf;EACA;IACE,sCAAsC;EACxC;EACA;IACE,0BAA0B;EAC5B;AACF","sourcesContent":["@media only screen and (min-width: 1440px) and (max-width: 2559px) {\n  .user_contribution_card_skeleton_width {\n    width: 550px;\n    margin: 10px;\n    height: 100px;\n  }\n  .top_contributors_section_mobile_view {\n    display: none;\n  }\n  .user_contributors_list {\n    grid-template-columns: 25% 25% 25% 25%;\n  }\n  .user_contribution_title_ta {\n    font-size: 28px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
