import { MENU_LOGO } from "@src/helpers/image_constants";
import { dynamic_set } from "@src/services/redux";
import { Drawer, Image } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import HeaderBarDrawer from "./header_bar_drawer";

const HeaderDrawerLogo = () => {
  const dispatch = useDispatch();
  // const { is_drawer_open } = useDynamicSelector("set_is_drawer_open");

  const handle_drawer_open = () => {
    dispatch(dynamic_set("set_is_drawer_open", { is_drawer_open: true }));
    // set_drawer_open(true);
  };
  const handle_drawer_close = () => {
    // set_drawer_open(false);
    dispatch(dynamic_set("set_is_drawer_open", { is_drawer_open: false }));
  };

  return (
    <>
      <div className="user_layout_drawer_container ">
        <Image
          preview={false}
          className="drawer_menu_icon"
          src={MENU_LOGO}
          onClick={handle_drawer_open}
        />
      </div>
      <HeaderBarDrawer handle_drawer_close={handle_drawer_close} />
    </>
  );
};

export default HeaderDrawerLogo;
