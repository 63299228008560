// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (min-width: 2560px) {
 
  .book_mark_container {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    gap: 11px;
    padding: 2%;
    width: 100%;
  }

  .profile_header {
    font-size: 50px;
  }
  .profile-body h5 {
    font-size: 40px;
  }
  .about-me > h6 {
    font-size: 35px;
  }
  .about-me > p {
    font-size: 30px !important ;
  }
  .contact-info > p,
  .user_mobile_number_container > p {
    font-size: 26px;
  }
  .edit-button {
    font-size: 24px;
  }
  .donations-header h6 {
    font-size: 38px;
  }
  .donations-header > #view-all {
    font-size: 24px;
  }
  .donation-item {
    padding: 12px 0px;
  }
  .donation-item span {
    font-size: 25px;
  }
  .bookmark_header h6 {
    font-size: 38px;
  }
  .bookmark-label {
    font-size: 19px;
  }
  .profile-body {
    margin-top: 90px;
  }
  .profile-img {
    width: 130px;
    height: 130px;
  }
  .profile-header {
    height: 140px;
  }

  
  .no_transaction{
    font-size:23px;
    margin-bottom: 20px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/screen_2560_layout/screen_2560_user_profile.css"],"names":[],"mappings":"AAAA;;EAEE;IACE,aAAa;IACb,sCAAsC;IACtC,SAAS;IACT,WAAW;IACX,WAAW;EACb;;EAEA;IACE,eAAe;EACjB;EACA;IACE,eAAe;EACjB;EACA;IACE,eAAe;EACjB;EACA;IACE,2BAA2B;EAC7B;EACA;;IAEE,eAAe;EACjB;EACA;IACE,eAAe;EACjB;EACA;IACE,eAAe;EACjB;EACA;IACE,eAAe;EACjB;EACA;IACE,iBAAiB;EACnB;EACA;IACE,eAAe;EACjB;EACA;IACE,eAAe;EACjB;EACA;IACE,eAAe;EACjB;EACA;IACE,gBAAgB;EAClB;EACA;IACE,YAAY;IACZ,aAAa;EACf;EACA;IACE,aAAa;EACf;;;EAGA;IACE,cAAc;IACd,mBAAmB;EACrB;AACF","sourcesContent":["@media only screen and (min-width: 2560px) {\n \n  .book_mark_container {\n    display: grid;\n    grid-template-columns: 25% 25% 25% 25%;\n    gap: 11px;\n    padding: 2%;\n    width: 100%;\n  }\n\n  .profile_header {\n    font-size: 50px;\n  }\n  .profile-body h5 {\n    font-size: 40px;\n  }\n  .about-me > h6 {\n    font-size: 35px;\n  }\n  .about-me > p {\n    font-size: 30px !important ;\n  }\n  .contact-info > p,\n  .user_mobile_number_container > p {\n    font-size: 26px;\n  }\n  .edit-button {\n    font-size: 24px;\n  }\n  .donations-header h6 {\n    font-size: 38px;\n  }\n  .donations-header > #view-all {\n    font-size: 24px;\n  }\n  .donation-item {\n    padding: 12px 0px;\n  }\n  .donation-item span {\n    font-size: 25px;\n  }\n  .bookmark_header h6 {\n    font-size: 38px;\n  }\n  .bookmark-label {\n    font-size: 19px;\n  }\n  .profile-body {\n    margin-top: 90px;\n  }\n  .profile-img {\n    width: 130px;\n    height: 130px;\n  }\n  .profile-header {\n    height: 140px;\n  }\n\n  \n  .no_transaction{\n    font-size:23px;\n    margin-bottom: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
