// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (min-width: 2560px) {
  .writer_profile_wrapper {
    padding: 2%;
    gap: 20px;
  }

  .writer_profile_details_container {
    width: 80%;
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    padding: 2%;
    gap: 20px;
    border-radius: 15px;
  }

  .writer_description {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .contributions_card_wrapper {
    width: 30%;
  }
  .contributions_card_container {
    width: 80%;
    display: flex;
    justify-content: space-between;
  }
  .about_writer {
    font-size: 22px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/screen_2560_layout/screen_2560_writer_profile.css"],"names":[],"mappings":"AAAA;EACE;IACE,WAAW;IACX,SAAS;EACX;;EAEA;IACE,UAAU;IACV,yBAAyB;IACzB,aAAa;IACb,8BAA8B;IAC9B,WAAW;IACX,SAAS;IACT,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,SAAS;EACX;EACA;IACE,UAAU;EACZ;EACA;IACE,UAAU;IACV,aAAa;IACb,8BAA8B;EAChC;EACA;IACE,eAAe;EACjB;AACF","sourcesContent":["@media only screen and (min-width: 2560px) {\n  .writer_profile_wrapper {\n    padding: 2%;\n    gap: 20px;\n  }\n\n  .writer_profile_details_container {\n    width: 80%;\n    background-color: #ffffff;\n    display: flex;\n    justify-content: space-between;\n    padding: 2%;\n    gap: 20px;\n    border-radius: 15px;\n  }\n\n  .writer_description {\n    display: flex;\n    flex-direction: column;\n    gap: 30px;\n  }\n  .contributions_card_wrapper {\n    width: 30%;\n  }\n  .contributions_card_container {\n    width: 80%;\n    display: flex;\n    justify-content: space-between;\n  }\n  .about_writer {\n    font-size: 22px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
