import React from "react";
import { UpOutlined } from "@ant-design/icons";
import { SlArrowUp } from "react-icons/sl";
import { HiArrowUp } from "react-icons/hi2";

const ScrollToTopButton = ({ handle_scroll_to_top, opacity }) => {
  return (
    <div
      className="scroll_to_top_icon_div"
      onClick={handle_scroll_to_top}
      style={{ opacity: opacity }}
    >
      <HiArrowUp className="scroll_to_top_icon" />
    </div>
  );
};
export default ScrollToTopButton;
