import React, { useEffect, useRef, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
// const HeaderBar = React.lazy(() =>
//   import("../components/auth/header_bar.jsx/header_bar")
// );
// const QuickAccessBar = React.lazy(() =>
//   import("../components/auth/quick_access_bar/quick_access_bar")
// );
// const Footer = React.lazy(() => import("../components/auth/footer/footer"));
// const SubmitClaim = React.lazy(() =>
//   import("../components/submit_claim/submit_claim")
// );
// const ScrollToTopButton = React.lazy(() =>
//   import("../components/scroll_to_top_button/scroll_to_top_button")
// );
// const HeaderSearchMobileView = React.lazy(() =>
//   import("../components/auth/header_bar.jsx/header_search_mobile_view")
// );
import { retrieve_item } from "@src/helpers/cryptos";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { dynamic_set } from "@src/services/redux";
import QuickAccessBar from "../components/auth/quick_access_bar/quick_access_bar";
import HeaderBar from "../components/auth/header_bar.jsx/header_bar";
import HeaderSearchMobileView from "../components/auth/header_bar.jsx/header_search_mobile_view";
import Footer from "../components/auth/footer/footer";
import SubmitClaim from "../components/submit_claim/submit_claim";
import ScrollToTopButton from "../components/scroll_to_top_button/scroll_to_top_button";
import { DOWNLOAD_APP } from "@src/helpers/image_constants";
import DownloadIcon from "../components/download_app/download_icon";

const UserLayout = (props) => {
  const top_ref = useRef(null);
  const { pathname } = useLocation();
  const user_id = retrieve_item("user_id");
  const is_subscribed = retrieve_item("is_subscribed");
  const session_id = retrieve_item("session_id");
  const [show_scroll_icon, set_show_scroll_icon] = useState(false);
  const [show_mobile_search, set_show_mobile_search] = useState(false);
  const [writing_image_opacity, set_writing_image_opacity] = useState(1);
  const dispatch = useDispatch();
  const [notificationPermission, setNotificationPermission] =
    useState("default");
  const [is_subscribed_successfully, set_is_subscribed_successfully] =
    useState(false);
  const [is_in_app, set_is_in_app] = useState(false);

  const handle_show_search = () => {
    set_show_mobile_search(!show_mobile_search);
  };

  const handle_scroll = () => {
    const scroll_element = top_ref?.current;
    if (scroll_element) {
      const scroll_position = scroll_element.scrollTop;
      set_show_scroll_icon(scroll_position > 100); // Show icon if scrolled more than 100px from the top
      set_writing_image_opacity(0.1);
      setTimeout(() => {
        set_writing_image_opacity(1);
      }, 1000);
    }
  };

  useEffect(() => {
    const scroll_element = top_ref?.current;
    if (scroll_element) {
      scroll_element.addEventListener("scroll", handle_scroll);
      return () => {
        scroll_element.removeEventListener("scroll", handle_scroll);
      };
    }
  }, []);

  useEffect(() => {
    dispatch(dynamic_set("set_is_drawer_open", { is_drawer_open: false }));
    const element = top_ref.current;
    if (element) {
      element.scrollTo({
        top: 0,
      });
    }
    if (pathname === "/search-results") {
      set_show_mobile_search(false);
    }
  }, [pathname]);

  const handle_scroll_to_top = () => {
    const element = top_ref.current;
    if (element) {
      element.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };
  // Register the service worker
  async function registerServiceWorker() {
    if ("serviceWorker" in navigator) {
      try {
        const registration = await navigator.serviceWorker.register(
          "/service-worker.js"
        );
        console.log(
          "Service Worker registered with scope:",
          registration.scope
        );

        // Wait until the service worker is active
        if (registration.installing) {
          console.log("Service worker installing...");
          await new Promise((resolve) => {
            registration.installing.addEventListener("statechange", (e) => {
              if (e.target.state === "activated") {
                console.log("Service worker activated");
                resolve();
              }
            });
          });
        } else if (registration.waiting) {
          console.log("Service worker installed but waiting...");
          await new Promise((resolve) => {
            registration.waiting.addEventListener("statechange", (e) => {
              if (e.target.state === "activated") {
                console.log("Service worker activated");
                resolve();
              }
            });
          });
        } else if (registration.active) {
          console.log("Service worker already active");
        }

        return registration;
      } catch (error) {
        console.error("Service Worker registration failed:", error);
      }
    }
    return null;
  }

  // Convert the VAPID public key from base64 to Uint8Array
  function urlBase64ToUint8Array(base64String) {
    const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding)
      .replace(/-/g, "+")
      .replace(/_/g, "/");
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }

    return outputArray;
  }

  // Subscribe the user to push notifications
  async function subscribeUserToPush(registration) {
    const publicVapidKey =
      "BPtcilrMjeOnYVrtcNatFpqb3wQPtg-p2a-00wfT-rJEcngS_ey7N8flyIrO1WGfgrAu1zbEKuoLKrUdHPoWum0";

    try {
      const subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(publicVapidKey),
      });

      console.log("Push subscription object:", subscription);
      return subscription;
    } catch (error) {
      console.error("Failed to subscribe the user:", error);
    }
    return null;
  }

  // Handle the subscription and send to the backend
  const handlePushSubscription = async () => {
    try {
      const registration = await registerServiceWorker();
      if (!registration) {
        console.error("Service Worker registration failed.");
        return;
      }

      const subscription = await subscribeUserToPush(registration);

      if (subscription) {
        const payload = {
          system_user_id: user_id,
          subscription,
        };

        const response = await fetch(process.env.REACT_APP_SUBSCRIBE, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });

        if (response.ok) {
          const data = await response.json();
          if (data.message === "Subscription added") {
            set_is_subscribed_successfully(true);
            toast.success("Notification Enabled");
          }
        } else {
          console.error("Failed to send subscription to backend.");
        }
      }
    } catch (error) {
      console.error("Push subscription error:", error);
    }
  };

  useEffect(() => {
    if ("Notification" in window) {
      if (Notification.permission === "default") {
        Notification.requestPermission().then((permission) => {
          setNotificationPermission(permission);
          if (permission === "granted") {
            handlePushSubscription(); // Subscribe user once they explicitly allow notifications
          }
        });
      }
    }
  }, []);

  return (
    <div ref={top_ref} className="main-container">
      <QuickAccessBar />
      <HeaderBar handle_show_search={handle_show_search} />
      {show_mobile_search && (
        <div
          style={{
            position: "sticky",
            top: "49px",
            zIndex: 999,
          }}
          className="user_layout_mobile_view_search"
        >
          <HeaderSearchMobileView />
        </div>
      )}
      <Outlet />
      <Footer />
      <SubmitClaim writing_image_opacity={writing_image_opacity} />
      {show_scroll_icon && (
        <ScrollToTopButton
          handle_scroll_to_top={handle_scroll_to_top}
          opacity={writing_image_opacity}
        />
      )}
    </div>
  );
};

export default UserLayout;
