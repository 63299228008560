// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (min-width: 768px) and (max-width: 1023px) {

  .user_dashboard_container {
    grid-template-columns: 100%;
    row-gap: 20px;
  }
  .user_profile_details_main_container {
    width: 100%;
  }
  .user_dashboard_third_container,
  .user_dashboard_second_container {
    flex-direction: row;
  }
  .empty_container_card {
    height: 250px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/screen_768_1023/screen_768_1023_user_donation.css"],"names":[],"mappings":"AAAA;;EAEE;IACE,2BAA2B;IAC3B,aAAa;EACf;EACA;IACE,WAAW;EACb;EACA;;IAEE,mBAAmB;EACrB;EACA;IACE,aAAa;IACb,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;EACrB;AACF","sourcesContent":["@media only screen and (min-width: 768px) and (max-width: 1023px) {\n\n  .user_dashboard_container {\n    grid-template-columns: 100%;\n    row-gap: 20px;\n  }\n  .user_profile_details_main_container {\n    width: 100%;\n  }\n  .user_dashboard_third_container,\n  .user_dashboard_second_container {\n    flex-direction: row;\n  }\n  .empty_container_card {\n    height: 250px;\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
