import { Form, Input } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const Name = () => {
  const { t } = useTranslation();
  return (
    <Form.Item
      name={"name"}
      rules={[{ required: true, message: t("please_enter_your_name") }]}
    >
      <Input placeholder={t("name")} />
    </Form.Item>
  );
};

export default Name;
