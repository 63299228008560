import { retrieve_item } from "@src/helpers/cryptos";
import { Button, Col, Form, Typography } from "antd";
import React, { useEffect, useState } from "react";
import Name from "./name";
import MobileNo from "./mobile_no";
import { useTranslation } from "react-i18next";
const ViralPostLink=React.lazy(()=>import( "./viral_post_link"));
const Description=React.lazy(()=>import( "./description"));
const UploadFile=React.lazy(()=>import( "./upload_file"));
const AppreciationModal=React.lazy(()=>import( "../custom_components/appreciation_modal/appreciation_modal"));
import { useAPIRequest } from "@src/helpers/hooks";
import { create_user_claim_mutation } from "@src/services/graphql/graphql_submit_claim";
import { dynamic_clear, useDynamicSelector } from "@src/services/redux";
import { useDispatch } from "react-redux";

const submit_claim_form = (props) => {
  const { on_close_modal } = props;
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const session_id = retrieve_item("session_id");

  const [appreciation_modal_open, set_appreciation_modal_open] =
    useState(false);
  const [is_button_disabled, set_is_button_disabled] = useState(true);

  const { status: create_user_claim_status, error: create_user_claim_error } =
    useDynamicSelector("create_user_claim");

  const claim_submit_request = useAPIRequest(
    "create_user_claim",
    create_user_claim_mutation
  );

  useEffect(() => {
    if (create_user_claim_status === "Success") {
      set_appreciation_modal_open(true);
      setTimeout(() => {
        set_appreciation_modal_open(false);
        on_close_modal();
      }, 10000);
      dispatch(dynamic_clear("create_user_claim"));
    } else if (create_user_claim_error) {
      // toast.error("Something went wrong");
      dispatch(dynamic_clear("create_user_claim"));
    }
  }, [create_user_claim_status, create_user_claim_error]);

  const on_claim_submit = (values) => {
    const { ref_url, attachment, description, name, mobile } = values;
    const file = attachment?.file;
    if (ref_url || (file && file.url) || description) {
      claim_submit_request(
        {
          json: {
            data: {
              name,
              mobile: String(mobile),
              ref_url,
              description,
              attachment: file?.url,
            },
          },
        },
        true
      );
    }
  };

  const on_values_change = (_, all_values) => {
    const { description, attachment, ref_url } = all_values;
    const is_field_filled =
      description || (attachment?.file?.url && attachment.file.url) || ref_url;
    set_is_button_disabled(!is_field_filled);
  };

  return (
    <React.Fragment>
      <div className="report_modal_title_container">
        <text className="report_modal_title">{t("submit_claim")}</text>
      </div>
      <Form
        layout="vertical"
        form={form}
        onFinish={on_claim_submit}
        onValuesChange={on_values_change}
        className="submit_claim_form"
      >
        {!session_id && (
          <>
            <Name />
            <MobileNo />
          </>
        )}
        <ViralPostLink />
        <UploadFile />
        <Description />

        <Col
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Form.Item>
         
            <Button
              type="primary"
              className={
                !is_button_disabled
                  ? "submit_button"
                  : "home_donate_button_disabled"
              }
              htmlType="submit"
              // onClick={handle_remove_profile}
            >
              <Typography style={{ color: "#FFFFFF" }}>
                {t("submit")}
              </Typography>
            </Button>
          </Form.Item>
        </Col>
      </Form>
      <AppreciationModal
        subtitle="We extend our sincere appreciation for your claim."
        message="Together, let's make an impact through your claim. Keep supporting us."
        popper_status={appreciation_modal_open}
        appreciation_modal_open={appreciation_modal_open}
        set_appreciation_modal={set_appreciation_modal_open}
      />
    </React.Fragment>
  );
};

export default submit_claim_form;
