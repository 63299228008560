import React, { useEffect, useRef } from "react";
import { Router } from "@src/route/router";
import { language } from "@src/helpers/constants";
import "react-multi-carousel/lib/styles.css";
import { store_item } from "./helpers/cryptos";
import { get_language } from "./helpers/functions";
import { useDispatch } from "react-redux";
import { dynamic_set } from "./services/redux";
import "react-phone-input-2/lib/style.css";

function App() {
  const dispatch = useDispatch();
  const contentRef = useRef(null);
  // store_item("language", {
  //   name: "english",
  //   id: language?.english_id,
  //   key: "en",
  // });
  useEffect(() => {
    get_language();
    dispatch(dynamic_set("dark_mode", { dark_mode: false }));
  }, []);
  useEffect(() => {
    const resizeContent = () => {
      const contentDiv = contentRef.current;
      if (contentDiv) {
        const newWidth = contentDiv.scrollWidth;
        dispatch(dynamic_set("screen_width", { size: newWidth }));
      }
    };
    resizeContent();

    window.addEventListener("resize", resizeContent);
    return () => window.removeEventListener("resize", resizeContent);
  }, []);
  return (
    <div ref={contentRef}>
      <Router />
    </div>
  );
}

export default App;
