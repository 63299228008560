// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .featured_article_image_component {
    width: 100% !important;
  }
  .featured_article_thumbnail_overlay_content {
    height: 120px;
    width: 82% !important;
  }
  .featured_article_button {
    width: 90px;
    font-size: 11px;
  }
  .featured_article_mobile_view_title {
    font-size: 20px;
    font-weight: 700;
  }
  .featured_article_mobile_view_sub_title {
    font-size: 15px;
  }
  .featured_article_content_mobile_view {
    row-gap: 20px;
  }
  .featured_article_title {
    width: 95%;
  }
  .featured_story_article_container {
    display: none !important;
  }
  .recommended_story_desktop_main_container {
    display: none;
  }
  .recommended_story_mobile_container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 95%;
    padding-bottom: 4%;
  }
  .featured_article_mobile_view_title_ta {
    font-size: 20px;
    font-weight: 700;
  }
  .recommended_story_mobile_title {
    font-size: 3px !important;
    color: red !important;
  }
  .recommended_story_mobile_main_container {
    margin-top: 20px;
  }
  .recommended_story_mobile_middle_container{
    display: block;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/screen_768_1023/screen_768_1023_article.css"],"names":[],"mappings":"AAAA;EACE;IACE,sBAAsB;EACxB;EACA;IACE,aAAa;IACb,qBAAqB;EACvB;EACA;IACE,WAAW;IACX,eAAe;EACjB;EACA;IACE,eAAe;IACf,gBAAgB;EAClB;EACA;IACE,eAAe;EACjB;EACA;IACE,aAAa;EACf;EACA;IACE,UAAU;EACZ;EACA;IACE,wBAAwB;EAC1B;EACA;IACE,aAAa;EACf;EACA;IACE,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,UAAU;IACV,kBAAkB;EACpB;EACA;IACE,eAAe;IACf,gBAAgB;EAClB;EACA;IACE,yBAAyB;IACzB,qBAAqB;EACvB;EACA;IACE,gBAAgB;EAClB;EACA;IACE,cAAc;EAChB;AACF","sourcesContent":["@media only screen and (min-width: 768px) and (max-width: 1023px) {\n  .featured_article_image_component {\n    width: 100% !important;\n  }\n  .featured_article_thumbnail_overlay_content {\n    height: 120px;\n    width: 82% !important;\n  }\n  .featured_article_button {\n    width: 90px;\n    font-size: 11px;\n  }\n  .featured_article_mobile_view_title {\n    font-size: 20px;\n    font-weight: 700;\n  }\n  .featured_article_mobile_view_sub_title {\n    font-size: 15px;\n  }\n  .featured_article_content_mobile_view {\n    row-gap: 20px;\n  }\n  .featured_article_title {\n    width: 95%;\n  }\n  .featured_story_article_container {\n    display: none !important;\n  }\n  .recommended_story_desktop_main_container {\n    display: none;\n  }\n  .recommended_story_mobile_container {\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n    width: 95%;\n    padding-bottom: 4%;\n  }\n  .featured_article_mobile_view_title_ta {\n    font-size: 20px;\n    font-weight: 700;\n  }\n  .recommended_story_mobile_title {\n    font-size: 3px !important;\n    color: red !important;\n  }\n  .recommended_story_mobile_main_container {\n    margin-top: 20px;\n  }\n  .recommended_story_mobile_middle_container{\n    display: block;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
