// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (min-width: 2000px) and (max-width: 2559px){
    .about_writer{
        font-size:19px
    } 
}`, "",{"version":3,"sources":["webpack://./src/styles/screen_2000_2559/screen_2000_2559_writer_profile.css"],"names":[],"mappings":"AAAA;IACI;QACI;IACJ;AACJ","sourcesContent":["@media only screen and (min-width: 2000px) and (max-width: 2559px){\n    .about_writer{\n        font-size:19px\n    } \n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
