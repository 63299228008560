import React, { useState } from "react";
import { Typography } from "antd";
import { get_language } from "@src/helpers/functions";
import { retrieve_item } from "@src/helpers/cryptos";

const QuickAccessLanguages = () => {
  const handle_language_change = () => {
    const new_language_key = get_language() !== "en" ? "ta" : "en";
    window.sessionStorage.setItem("chosen_language_key", new_language_key);

    window.location.assign(
      get_language() === "en"
        ? process.env.REACT_APP_EN_WEB_URL
        : process.env.REACT_APP_WEB_URL
    );
  };
  return (
    <div className="quick_access_language_container">
      <Typography
        onClick={() =>
          get_language() !== "en" && handle_language_change("tamil")
        }
        className={`quick_access_bookmark_text ${
          get_language() !== "ta" ? "selected" : ""
        }`}
      >
        {"தமிழ்"}
      </Typography>
      <span className="language_divider"> | </span>
      <Typography
        onClick={() =>
          get_language() !== "ta" && handle_language_change("english")
        }
        className={`quick_access_bookmark_text ${
          get_language() !== "en" ? "selected" : ""
        }`}
      >
        {"English"}
      </Typography>
    </div>
  );
};

export default QuickAccessLanguages;
