// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.breadcrumb_container {
  display: flex;
  width: 100%;
  justify-content: center;
  background-color: var(--background-theme-color);
}

.breadcrumb_content {
  color: #fff !important;
}
.breadcrumb_link {
  color: #fff !important;
}
.breadcrumb_container .ant-breadcrumb-separator {
  color: #fff;
}
.breadcrumb_display_props {
  width: 90%;
}
`, "",{"version":3,"sources":["webpack://./src/styles/layout/breadcrumb.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,uBAAuB;EACvB,+CAA+C;AACjD;;AAEA;EACE,sBAAsB;AACxB;AACA;EACE,sBAAsB;AACxB;AACA;EACE,WAAW;AACb;AACA;EACE,UAAU;AACZ","sourcesContent":[".breadcrumb_container {\n  display: flex;\n  width: 100%;\n  justify-content: center;\n  background-color: var(--background-theme-color);\n}\n\n.breadcrumb_content {\n  color: #fff !important;\n}\n.breadcrumb_link {\n  color: #fff !important;\n}\n.breadcrumb_container .ant-breadcrumb-separator {\n  color: #fff;\n}\n.breadcrumb_display_props {\n  width: 90%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
