import React from "react";
import { Menu, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@src/route/my_routes";

const { SubMenu } = Menu;

const QuickAccessBookmarks = () => {
  const navigate = useNavigate();

  const click_to_view_bookmarks = (e) => {
    if (e.key === "articles") {
      navigate(ROUTES.BOOKMARK_ARTICLES);
    } else {
      navigate(ROUTES.BOOKMARK_FACT_CHECK);
    }
  };

  const menu_items = [
    {
      key: "bookmarks",
      label: "Bookmarks",
      children: [
        {
          type: "article",
          label: "Articles",
          key: "articles",
        },
        {
          type: "fact-check",
          label: "Fact Checks",
          key: "fact-check",
        },
      ],
    },
  ];
  return (
    <div className="quick_access_bookmark_container">
      <div className="segment_container">
        <Menu
          mode="horizontal"
          className="main_menu"
          selectedKeys={[]}
          onClick={click_to_view_bookmarks}
        >
          {menu_items.map((item) => (
            <SubMenu
              key={item.key}
              title={item.label}
              className="bookmarks-submenu"
            >
              {item.children.map((child) => (
                <Menu.Item className="bookmarks-submenu-action" key={child.key}>
                  {child.label}
                </Menu.Item>
              ))}
            </SubMenu>
          ))}
        </Menu>
      </div>
    </div>
  );
};

export default QuickAccessBookmarks;
