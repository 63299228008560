// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (min-width: 0px) and (max-width: 767px) {
  .user_contribution_card_skeleton_width {
    width: 350px;
    margin: 10px;
    height: 100px;
  }
  .all_stories_and_contributors_container {
    display: none;
  }
  .mobile_title_view_container {
    display: flex;
    width: 90%;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .contributors_image_mobile_view_section {
    width: 90%;
    height: 100px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .user_contributors_list {
    grid-template-columns: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .user_contribution_title_ta {
    font-size: 14px !important;
    font-weight: 800 !important;
  }

  .home_user_contribute_mobile_title {
    font-size: 12px !important;
    line-height: 19px;
  }
  .user_contribution_trending_stories_skeleton :where(.css-dev-only-do-not-override-zg0ahe).ant-skeleton.ant-skeleton-element .ant-skeleton-input{
    height: 200px !important;
    background: #d9d9d9;
    width: 425px !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/screen_767/screen_767_user_contribution.css"],"names":[],"mappings":"AAAA;EACE;IACE,YAAY;IACZ,YAAY;IACZ,aAAa;EACf;EACA;IACE,aAAa;EACf;EACA;IACE,aAAa;IACb,UAAU;IACV,uBAAuB;IACvB,gBAAgB;IAChB,mBAAmB;EACrB;;EAEA;IACE,UAAU;IACV,aAAa;IACb,iBAAiB;IACjB,oBAAoB;EACtB;EACA;IACE,2BAA2B;IAC3B,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;EACrB;EACA;IACE,0BAA0B;IAC1B,2BAA2B;EAC7B;;EAEA;IACE,0BAA0B;IAC1B,iBAAiB;EACnB;EACA;IACE,wBAAwB;IACxB,mBAAmB;IACnB,uBAAuB;EACzB;AACF","sourcesContent":["@media only screen and (min-width: 0px) and (max-width: 767px) {\n  .user_contribution_card_skeleton_width {\n    width: 350px;\n    margin: 10px;\n    height: 100px;\n  }\n  .all_stories_and_contributors_container {\n    display: none;\n  }\n  .mobile_title_view_container {\n    display: flex;\n    width: 90%;\n    justify-content: center;\n    margin-top: 20px;\n    margin-bottom: 20px;\n  }\n\n  .contributors_image_mobile_view_section {\n    width: 90%;\n    height: 100px;\n    padding-top: 10px;\n    padding-bottom: 10px;\n  }\n  .user_contributors_list {\n    grid-template-columns: 100%;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n  }\n  .user_contribution_title_ta {\n    font-size: 14px !important;\n    font-weight: 800 !important;\n  }\n\n  .home_user_contribute_mobile_title {\n    font-size: 12px !important;\n    line-height: 19px;\n  }\n  .user_contribution_trending_stories_skeleton :where(.css-dev-only-do-not-override-zg0ahe).ant-skeleton.ant-skeleton-element .ant-skeleton-input{\n    height: 200px !important;\n    background: #d9d9d9;\n    width: 425px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
