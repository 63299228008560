// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (min-width: 2000px) and (max-width: 2559px) {
  .featured_story_article_title_ta {
    font-size: 26px;
  }
  .featured_article_title {
    width: 90%;
  }
  .featured_article_image_component {
    width: 60% !important;
  position: relative;

  }
  .featured_story_image_skeleton {
    height: 600px !important;
  }
  .featured_article_thumbnail_overlay_content {
    height: 160px;
    width: 65% !important;
  }
  .featured_story_article {
    font-size: 35px;
  }
  .featured_article_button {
    font-size: 15px;
  }
  .featured_story_article_title {
    font-size: 26px;
  }
  .featured_article_sub_title {
    font-size: 23px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/screen_2000_2559/screen_2000_2559_articles.css"],"names":[],"mappings":"AAAA;EACE;IACE,eAAe;EACjB;EACA;IACE,UAAU;EACZ;EACA;IACE,qBAAqB;EACvB,kBAAkB;;EAElB;EACA;IACE,wBAAwB;EAC1B;EACA;IACE,aAAa;IACb,qBAAqB;EACvB;EACA;IACE,eAAe;EACjB;EACA;IACE,eAAe;EACjB;EACA;IACE,eAAe;EACjB;EACA;IACE,eAAe;EACjB;AACF","sourcesContent":["@media only screen and (min-width: 2000px) and (max-width: 2559px) {\n  .featured_story_article_title_ta {\n    font-size: 26px;\n  }\n  .featured_article_title {\n    width: 90%;\n  }\n  .featured_article_image_component {\n    width: 60% !important;\n  position: relative;\n\n  }\n  .featured_story_image_skeleton {\n    height: 600px !important;\n  }\n  .featured_article_thumbnail_overlay_content {\n    height: 160px;\n    width: 65% !important;\n  }\n  .featured_story_article {\n    font-size: 35px;\n  }\n  .featured_article_button {\n    font-size: 15px;\n  }\n  .featured_story_article_title {\n    font-size: 26px;\n  }\n  .featured_article_sub_title {\n    font-size: 23px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
