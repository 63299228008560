// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (min-width: 0px) and (max-width: 767px) {
  .comments_parent_comment_text {
    width: 68%;
    font-size: 13px;
  }
  .comments_container {
    padding: 0px !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/screen_767/screen_767_comments.css"],"names":[],"mappings":"AAAA;EACE;IACE,UAAU;IACV,eAAe;EACjB;EACA;IACE,uBAAuB;EACzB;AACF","sourcesContent":["@media only screen and (min-width: 0px) and (max-width: 767px) {\n  .comments_parent_comment_text {\n    width: 68%;\n    font-size: 13px;\n  }\n  .comments_container {\n    padding: 0px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
