// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (min-width: 2560px) {
  .support_us_section_container {
    padding: 2% 5% 5% 7%;
  }
  .support_us_donation {
    display: none;
  }
  .support_us_section_wrapper {
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/screen_2560_layout/screen_2560_support_us.css"],"names":[],"mappings":"AAAA;EACE;IACE,oBAAoB;EACtB;EACA;IACE,aAAa;EACf;EACA;IACE,UAAU;IACV,aAAa;IACb,sBAAsB;IACtB,SAAS;EACX;AACF","sourcesContent":["@media only screen and (min-width: 2560px) {\n  .support_us_section_container {\n    padding: 2% 5% 5% 7%;\n  }\n  .support_us_donation {\n    display: none;\n  }\n  .support_us_section_wrapper {\n    width: 60%;\n    display: flex;\n    flex-direction: column;\n    gap: 15px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
