// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.support_us_header_container {
  width: 100%;
  height: 100px;
  background-color: var(--background-theme-color) !important;
  display: flex;
  justify-content: center;
}
.white_font {
  color: #ffffff;
}
.support_us_section_container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.support_us_donation_main_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: var(--gradient-color) !important;
  padding: 40px;
  border-radius: 17px;
  gap: 5px;
}
.donation_input_wrapper {
  display: flex;
  justify-content: center;
  height: 100px;
}

.amount_format_card_content {
  padding: 5px;
  background-color: #fff;
  color: black;
  border-radius: 8px;
  cursor: pointer;
  width: 18%;
  font-size: 13px;
  display: flex;
  justify-content: center;
}
.selected_amount_format_card_content {
  background-color: var(--become-member-button);
  color: white;
  padding: 5px;
  border-radius: 15px;
  cursor: pointer;
  width: 18%;
  font-size: 13px;
  display: flex;
  justify-content: center;
}
.support_donation_amount_input_container {
  width: 50%;
  justify-content: center;
  display: flex;
  gap: 22px;
}
.support_us_card {
  width: 30%;
}
`, "",{"version":3,"sources":["webpack://./src/styles/layout/support_us.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,0DAA0D;EAC1D,aAAa;EACb,uBAAuB;AACzB;AACA;EACE,cAAc;AAChB;AACA;EACE,WAAW;EACX,aAAa;EACb,8BAA8B;AAChC;AACA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,4CAA4C;EAC5C,aAAa;EACb,mBAAmB;EACnB,QAAQ;AACV;AACA;EACE,aAAa;EACb,uBAAuB;EACvB,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,sBAAsB;EACtB,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,UAAU;EACV,eAAe;EACf,aAAa;EACb,uBAAuB;AACzB;AACA;EACE,6CAA6C;EAC7C,YAAY;EACZ,YAAY;EACZ,mBAAmB;EACnB,eAAe;EACf,UAAU;EACV,eAAe;EACf,aAAa;EACb,uBAAuB;AACzB;AACA;EACE,UAAU;EACV,uBAAuB;EACvB,aAAa;EACb,SAAS;AACX;AACA;EACE,UAAU;AACZ","sourcesContent":[".support_us_header_container {\n  width: 100%;\n  height: 100px;\n  background-color: var(--background-theme-color) !important;\n  display: flex;\n  justify-content: center;\n}\n.white_font {\n  color: #ffffff;\n}\n.support_us_section_container {\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n}\n.support_us_donation_main_container {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  background: var(--gradient-color) !important;\n  padding: 40px;\n  border-radius: 17px;\n  gap: 5px;\n}\n.donation_input_wrapper {\n  display: flex;\n  justify-content: center;\n  height: 100px;\n}\n\n.amount_format_card_content {\n  padding: 5px;\n  background-color: #fff;\n  color: black;\n  border-radius: 8px;\n  cursor: pointer;\n  width: 18%;\n  font-size: 13px;\n  display: flex;\n  justify-content: center;\n}\n.selected_amount_format_card_content {\n  background-color: var(--become-member-button);\n  color: white;\n  padding: 5px;\n  border-radius: 15px;\n  cursor: pointer;\n  width: 18%;\n  font-size: 13px;\n  display: flex;\n  justify-content: center;\n}\n.support_donation_amount_input_container {\n  width: 50%;\n  justify-content: center;\n  display: flex;\n  gap: 22px;\n}\n.support_us_card {\n  width: 30%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
