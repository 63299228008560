// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (min-width: 1440px) and (max-width: 2559px) {
  .mobile_view_for_first_section {
    display: none;
  }
  .user_layout_mobile_view_search {
    display: none;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/screen_1440_1999/screen_1440_2559_search_results.css"],"names":[],"mappings":"AAAA;EACE;IACE,aAAa;EACf;EACA;IACE,aAAa;EACf;AACF","sourcesContent":["@media only screen and (min-width: 1440px) and (max-width: 2559px) {\n  .mobile_view_for_first_section {\n    display: none;\n  }\n  .user_layout_mobile_view_search {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
