// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (min-width: 1024px) and (max-width: 1439px) {
  .top_contributors_section {
    width: 50%;
  }

  .top_contributors_image_container {
    width: 180px;
    position: relative;
  }

  .top_contributors_image {
    width: 150px;
    border-radius: 10px;
  }
  .top_contributors_title {
    margin-left: 60px;
  }
  .overlay_content {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 150px;
    height: 80px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    background-color: #fff;
    position: absolute;
    right: 0;
    bottom: 0;
    align-items: center;
    justify-content: center;
  }
  .user_contribution_card_skeleton_width {
    width: 400px;
    margin: 10px;
    height: 100px;
  }
  .top_contributors_section_mobile_view {
    display: none;
  }
  .user_contributors_list {
    grid-template-columns: 25% 25% 25% 25%;
  }
  .user_contribution_title_ta {
    font-size: 25px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/screen_1024_1439/screen_1024_1439_user_contribution.css"],"names":[],"mappings":"AAAA;EACE;IACE,UAAU;EACZ;;EAEA;IACE,YAAY;IACZ,kBAAkB;EACpB;;EAEA;IACE,YAAY;IACZ,mBAAmB;EACrB;EACA;IACE,iBAAiB;EACnB;EACA;IACE,aAAa;IACb,sBAAsB;IACtB,eAAe;IACf,YAAY;IACZ,YAAY;IACZ,0CAA0C;IAC1C,sBAAsB;IACtB,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,mBAAmB;IACnB,uBAAuB;EACzB;EACA;IACE,YAAY;IACZ,YAAY;IACZ,aAAa;EACf;EACA;IACE,aAAa;EACf;EACA;IACE,sCAAsC;EACxC;EACA;IACE,eAAe;EACjB;AACF","sourcesContent":["@media only screen and (min-width: 1024px) and (max-width: 1439px) {\n  .top_contributors_section {\n    width: 50%;\n  }\n\n  .top_contributors_image_container {\n    width: 180px;\n    position: relative;\n  }\n\n  .top_contributors_image {\n    width: 150px;\n    border-radius: 10px;\n  }\n  .top_contributors_title {\n    margin-left: 60px;\n  }\n  .overlay_content {\n    display: flex;\n    flex-direction: column;\n    flex-wrap: wrap;\n    width: 150px;\n    height: 80px;\n    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);\n    background-color: #fff;\n    position: absolute;\n    right: 0;\n    bottom: 0;\n    align-items: center;\n    justify-content: center;\n  }\n  .user_contribution_card_skeleton_width {\n    width: 400px;\n    margin: 10px;\n    height: 100px;\n  }\n  .top_contributors_section_mobile_view {\n    display: none;\n  }\n  .user_contributors_list {\n    grid-template-columns: 25% 25% 25% 25%;\n  }\n  .user_contribution_title_ta {\n    font-size: 25px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
