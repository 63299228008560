import React, { useState } from "react";
import { Image, Input, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { SEARCH_LOGO } from "@src/helpers/image_constants";
import { useLocation, useNavigate } from "react-router-dom";

const HeaderSearch = (props) => {
  const { handle_show_search } = props;
  const { pathname } = useLocation();
  const [search_string, set_search_string] = useState("");
  const navigate = useNavigate(); // Corrected hook

  const handle_search = () => {
    if (search_string.trim() !== "") {
      const queryParams = new URLSearchParams();
      queryParams.set("type", search_string.trim());
      navigate({
        pathname: "/search-results",
        search: `?${queryParams.toString()}`,
      });

      set_search_string("");
    }
  };

  return (
    <div className="user_layout_search_container">
      <Input
        className="user_layout_search_input non-mobile_screen"
        allowClear
        prefix={
          <Space>
            <SearchOutlined className="header_search_icon" />
          </Space>
        }
        value={search_string}
        onChange={(e) => set_search_string(e.target.value)}
        onPressEnter={handle_search}
      />
      {pathname !== "/search-results" && (
        <Image
          preview={false}
          className="search_mobile_icon mobile_screen"
          src={SEARCH_LOGO}
          onClick={handle_show_search}
          style={{ cursor: "pointer", zIndex: 999 }}
        />
      )}
    </div>
  );
};

export default HeaderSearch;
