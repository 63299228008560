import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

const RedirectHandler = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location?.state?.search);
  const param = queryParams.get("p");
  const url = window.location.href.split("/").slice(0, 3).join("/") + "/";
  const [loading, set_loading] = useState(true);
  const [error, set_error] = useState(null);
  console.log("queryParams", location, queryParams, url, param);

  useEffect(() => {
    if (param) {
      axios
        .post(
          process.env.REACT_APP_SITE_REDIRECT_API,
          {
            referer: url,
            p: param,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          const redirectUrl = response?.data?.result?.url;
          if (redirectUrl) {
            console.log("redirectUrl", redirectUrl);
            window.location.replace(redirectUrl); // Redirect to the URL
          } else {
            throw new Error("Redirect URL not found");
          }
        })
        .catch((err) => {
          set_error(err.message);
        })
        .finally(() => {
          set_loading(false);
        });
    } else {
      console.log("No 'p' parameter found in the URL.");
      set_loading(false);
    }
  }, [param]);

  if (loading) {
    return <div>Loading...</div>; // Show loading state
  }

  if (error) {
    return <div>Error: {error}</div>; // Show error state
  }

  return null; // No need to render anything as we're redirecting
};

export default RedirectHandler;
