// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .mobile_view_for_first_section {
    display: none;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/screen_768_1023/screen_768_1023_search_results.css"],"names":[],"mappings":"AAAA;EACE;IACE,aAAa;EACf;AACF","sourcesContent":["@media only screen and (min-width: 768px) and (max-width: 1023px) {\n  .mobile_view_for_first_section {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
