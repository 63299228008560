// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .user_profile_background {
    height: fit-content;
  }
  .bookmark_title_container {
    padding-left: 20px;
    padding-right: 20px;
  }
  .book_mark_container {
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 11px;
    padding: 2%;
    width: 100%;
  }
  .profile-card {
    min-height: 420px;
  }
  #donations,
  #claims {
    width: 65%;
    height: 100%;
  }
  #bookmarks,
  #contributions {
    width: 30%;
  }
  .bookmark_body_container {
    height: 85%;
  }

    
  .no_transaction{
    font-size:20px;
    margin-bottom: 20px;
  }
 
}
`, "",{"version":3,"sources":["webpack://./src/styles/screen_768_1023/screen_768_1023_user_profile.css"],"names":[],"mappings":"AAAA;EACE;IACE,mBAAmB;EACrB;EACA;IACE,kBAAkB;IAClB,mBAAmB;EACrB;EACA;IACE,aAAa;IACb,8BAA8B;IAC9B,SAAS;IACT,WAAW;IACX,WAAW;EACb;EACA;IACE,iBAAiB;EACnB;EACA;;IAEE,UAAU;IACV,YAAY;EACd;EACA;;IAEE,UAAU;EACZ;EACA;IACE,WAAW;EACb;;;EAGA;IACE,cAAc;IACd,mBAAmB;EACrB;;AAEF","sourcesContent":["@media only screen and (min-width: 768px) and (max-width: 1023px) {\n  .user_profile_background {\n    height: fit-content;\n  }\n  .bookmark_title_container {\n    padding-left: 20px;\n    padding-right: 20px;\n  }\n  .book_mark_container {\n    display: grid;\n    grid-template-columns: 50% 50%;\n    gap: 11px;\n    padding: 2%;\n    width: 100%;\n  }\n  .profile-card {\n    min-height: 420px;\n  }\n  #donations,\n  #claims {\n    width: 65%;\n    height: 100%;\n  }\n  #bookmarks,\n  #contributions {\n    width: 30%;\n  }\n  .bookmark_body_container {\n    height: 85%;\n  }\n\n    \n  .no_transaction{\n    font-size:20px;\n    margin-bottom: 20px;\n  }\n \n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
