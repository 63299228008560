// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (min-width: 1440px) and (max-width: 2559px) {
  .login_page_logo_container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 90%;
  }
  .user_profile_container {
    width: 45px;
    height: 45px;
  }
  .sign_up_input {
    width: 300px !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/screen_1440_1999/screen_1440_2559_login.css"],"names":[],"mappings":"AAAA;EACE;IACE,aAAa;IACb,mBAAmB;IACnB,2BAA2B;IAC3B,UAAU;EACZ;EACA;IACE,WAAW;IACX,YAAY;EACd;EACA;IACE,uBAAuB;EACzB;AACF","sourcesContent":["@media only screen and (min-width: 1440px) and (max-width: 2559px) {\n  .login_page_logo_container {\n    display: flex;\n    flex-direction: row;\n    justify-content: flex-start;\n    width: 90%;\n  }\n  .user_profile_container {\n    width: 45px;\n    height: 45px;\n  }\n  .sign_up_input {\n    width: 300px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
